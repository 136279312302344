import Vue from "vue";
import titleMixin from "@/mixins/title";
export default Vue.extend({
    mixins: [titleMixin],
    data() {
        return {
            title: this.$t("employeeRoles.create.title"),
            employeeRole: {
                name: "",
                description: ""
            },
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    },
                    description: {
                        maxLength: 500
                    }
                }
            }
        };
    },
    methods: {
        create() {
            return this.$store.dispatch("employeeRole/create", {
                employeeRole: this.employeeRole
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("employeeRoles.create.toastSuccessTitle"), this.$t("employeeRoles.create.toastSuccessContent", {
                name: this.employeeRole.name
            }));
            return this.$router.push("/employee-roles");
        },
        cancel() {
            return this.$router.push("/employee-roles");
        }
    }
});
