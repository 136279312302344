import Vue from "vue";
export default Vue.extend({
    data() {
        return {
            title: ""
        };
    },
    mounted() {
        this.updateTitle();
    },
    methods: {
        setTitle(value) {
            this.title = value;
        },
        updateTitle() {
            document.title = this.$t("shared.appName");
            if (this.title !== undefined)
                document.title += ` - ${this.title}`;
        }
    },
    watch: {
        title() {
            this.updateTitle();
        }
    }
});
